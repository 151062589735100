import React from 'react'

import $ from 'jquery';

import { Helmet } from "react-helmet";

import LazyLoadingList from '../components/common/LazyLoadingList'

import Product from '../components/Product'

import General from '../utils/General'
import Backend from '../utils/Backend'
import ScriptCache from '../utils/ScriptCache'

export default class Shop extends React.Component {
  constructor(props) {
    super(props)

    let category = {
      label: "All",
      value: null
    }

    let orderBy = {
      label: "Latest",
      value: "-created_at"
    }

    this.state = {
      category,
      orderBy,
      loading: true,
      loadingShop: true,
      initialProoductsLoading: true,
      categories: [category],
      orderBys: [
        { ...orderBy },
        {
          label: "High to Low",
          value: "-price"
        },
        {
          label: "Low to High",
          value: "price"
        }
      ],
      shop: props.shop,
      website: props.website,
      endpoint: `${window.Api.Base}/products?shop_id=${props.website.id}&order_by=${orderBy.value}&active=true&search_term=`
    }

    this.lazyLoadingList = React.createRef()
  }

  componentDidMount() {
    this._setup()
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _setup(){
    let {
      shop
    } = this.state

    if(!shop){
      return null
    }

    this.setState({
      shop,
      loadingShop: false,
    }, () => {
      this._loadCategories()
      this._loadJs()
    })
  }

  _loadCategories(){
    let {
      shop,
      category,
      categories
    } = this.state

    Backend.getProductCategories(shop)
    .then(returnedCategories => {
      let slug = window.location.pathname.replace("/shop/category/", "")
      returnedCategories.forEach(returnedCategory => {
        let categoryOption = {
          label: returnedCategory.name,
          value: returnedCategory.id
        }
        categories.push(categoryOption)
        if(returnedCategory.slug === slug){
          category = categoryOption
        }
      })
      this.setState({
        category,
        categories,
        loading: false,
      })
    })
    .catch(error => {
      console.warn(error)
    })
  }

  _loadJs(){
    ScriptCache.loadDefaults()
    setTimeout(() => {
      General.updateAll()
    }, 300)
  }

  refresh() {
    let {
      category,
      orderBy,
      website,
      searchTerm
    } = this.state

    let current = this.lazyLoadingList.current

    let endpoint = `${window.Api.Base}/products?shop_id=${website.id}&order_by=${orderBy.value}&active=true&search_term=${searchTerm || ""}`
    if(category.value){
      endpoint += `&category_id=${category.value}`
    }

    if(current){
      this.setState({endpoint})
    }
  }

  _handleSearch = General.debounce(() => {
    this.refresh()
  }, 500)

  _renderProducts(){
    let {
      loading,
      endpoint
    } = this.state

    if(loading){
      return this._renderProductsLoading()
    }

    return (
      <LazyLoadingList
        ref={this.lazyLoadingList}
        grow={true}
        endpoint={endpoint}
        onItemUpdated={() => {
          this.setState({ initialProoductsLoading: false })
        }}
        renderItem={product => {
          return (
            <Product
              product={product}
              onAddToCartPressed={(product, variant) => this.props.onAddToCartPressed(product, variant)}
            />
          )
        }}
        renderInitialLoading={() => this._renderProductsLoading()}
        renderLoadingMore={() => this._renderProductsLoading()}
      />
    )
  }

  _renderProductsLoading(){
    return (
        <>
          <Product/>
          <Product/>
          <Product/>
        </>
    )
  }

  _renderFilters(){
    let {
      shop,
      loading,
      category,
      categories,
      orderBy,
      orderBys,
      initialProoductsLoading
    } = this.state

    if(loading || initialProoductsLoading){
      return (
        <div className="row filters align-items-center" style={{ height: 50}}>

            <div className="col-md-3 shimmer h-100"/>

            <div className="col-md-2"/>

            <div className="col-md-3 shimmer h-100"/>

            <div className="col-md-1"/>

            <div className="col-md-3 shimmer h-100"/>
        </div>
      )
    }

    return (
      <>
        <div className="row filters align-items-center">

            <div className="col-auto">
                <h2 className="m-0">{ shop.title }</h2>
            </div>

            <div className="col-md-3 filter-search ml-auto">
                <div className="input-search">
                  <input
                    type="search" name="search" placeholder="Search..."
                    onClick={e => {
                      $('.filters').addClass('search-expand');
                    }}
                    onBlur={e => {
                      $('.filters').removeClass('search-expand');
                    }}
                    onChange={e => {
                      this.setState({
                        searchTerm: e.target.value
                      }, () => this._handleSearch())
                    }}
                  />
                  <button>
                    <span class="material-icons">search</span>
                  </button>
                </div>
            </div>

            <div className="col-md-3 filter-category">
                <div className="input-select">
                    <select
                      value={category.value}
                      onChange={e => {
                        let value = e.target.value === "All" ? null : parseInt(e.target.value)
                        let category = categories.find(categoryOption => categoryOption.value === value)

                        this.setState({ category }, () => this.refresh())
                      }}
                    >
                      {
                        categories.map(category => {
                          return (
                            <option value={category.value}>{category.label}</option>
                          )
                        })
                      }
                    </select>
                </div>
            </div>

            <div className="col-md-3 filter-orderby">
                <div className="input-select">
                    <select
                      value={orderBy.value}
                      onChange={e => {
                        let orderBy = orderBys.find(orderBy => orderBy.value === e.target.value)
                        this.setState({ orderBy }, () => this.refresh())
                      }}
                    >
                      {
                        orderBys.map(orderBy => {
                          return (
                            <option value={orderBy.value}>{orderBy.label}</option>
                          )
                        })
                      }
                    </select>
                </div>

            </div>
        </div>


      </>
    )
  }

  _renderMeta(){
    let {
      shop,
    } = this.state

    if(shop == null){
       return null
    }
    let website = shop.website

    let name = "Shop | "+website.name
    let favIconUrl = website.favicon ? website.favicon.original : "/favicon.png"

    return (
      <Helmet>
          <title>{ name }</title>
          <meta name="description" content={ shop.description || website.descrption } />
          <meta name="keywords" content={ shop.keywords || website.keywords } />
          <link rel="icon" href={ favIconUrl }/>
          <meta property="og:title" content={ website.name }/>
          <meta property="og:image" content={ favIconUrl }/>
          <meta property="og:description" content={ shop.description || website.descrption }/>
          <meta property="og:type" content="website"/>
          <meta property="og:url" content={ window.location.href }/>
      </Helmet>
    )
  }

  render() {
    let {
    } = this.state

    return (
      <div className="main-container page shop">
        { this._renderMeta() }
        <section className="product-blocks">
            <div className="container">

                { this._renderFilters() }

                <div className="row mt-5">

                  { this._renderProducts() }

                </div>

            </div>
        </section>
      </div>
    )
  }
}
