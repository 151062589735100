import React from 'react'
import $ from "jquery";

import { Helmet } from "react-helmet";

import RelatedProduct from '../components/Product'

import { getImageUrl, Sizes } from '../components/blocks/editable/Img'
import ShareModal from '../components/modal/ShareModal'

import General from '../utils/General'
import ScriptCache from '../utils/ScriptCache'
import Currency from '../utils/Currency'
import Notify from '../utils/Notify'
import Cart from '../utils/Cart'

export default class Product extends React.Component {
  constructor(props){
    super(props)

    // key is incremented as the slider js functions
    // overwrite the html so we want to refresh it
    // as React is out of sync when
    // adding/editing/deleting the images
    this.state = {
      key: 0,
      loading: true,
      quantity: 1,
      variant: [],
      website: props.website,
      product: props.product,
      options: []
    }

    this.quantityInput = React.createRef()
  }

  componentDidMount() {
    this._setup()
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _setup(){
    let {
      product,
      options
    } = this.state

    if(product.featured_image){
      product.images.unshift(product.featured_image)
    }

    options = product.options ? product.options.map(optionName => {return {name:optionName, values: []}}) : []

    product.variants.map(variant => {
      variant.options.map((option, index) => {
        options[index].values.push({name: option})
      })
    })

    options.map(productOption => {
      productOption.values = productOption.values.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.name === value.name
        ))
      )
    })

    this.setState({
      product,
      options,
      loading: false
    }, () => this._loadJs())
  }

  _loadJs(){
    ScriptCache.loadDefaults()
    setTimeout(() => {
      General.updateAll()
    }, 500)
    this._mobileAddToCartStickyBtn();
  }

  _mobileAddToCartStickyBtn(){
    try {
      setTimeout(() => {
        var elementButton = $('.m-fixed-btn-addtocart');
        var elementLiveChat = $('div.live-chat.main-container .live-chat-icon');
        // checks the window is less than 768px
        if (elementButton.length && $(window).width() < 768) {

          var distance = elementButton.offset().top + elementButton.outerHeight();

          $(window).scroll(function () {

            if ($(window).scrollTop() >= distance) {

              if (!elementButton.hasClass("affix")) {
                elementButton.addClass("affix");
              }

              if (elementLiveChat.length && !elementLiveChat.hasClass("up-from-btm")) {
                elementLiveChat.addClass("up-from-btm");
              }

            } else {

              if (elementButton.hasClass("affix")) {
                elementButton.removeClass("affix");
              }

              if (elementLiveChat.length && elementLiveChat.hasClass("up-from-btm")) {
                elementLiveChat.removeClass("up-from-btm");
              }

            }

        });

        }
      }, 1000)
    }
    catch (err) {
        console.error(err.message);
    }
  }

  _handleAddToCart(){
    let {
      variant,
      product,
      quantity
    } = this.state

    if(variant.length !== product.options.length){
      Notify.error("Please select all product options")
      return
    }

    quantity = parseInt(this.quantityInput.current.value)
    variant = Cart.getVariant(product, variant)

    this.props.onAddToCartPressed(product, variant, quantity)
  }

  _renderPrice(product, variant){

    let price = product.on_sale ? product.sale_price : product.price
    if((product.options?.length > 0) && (variant.length === product.options.length)){
      variant = Cart.getVariant(product, variant)
      if(variant){
        price = variant.price
      }
    }

    if(product.on_sale){
      return (
        <>
          <span class="h4 type--strikethrough m-0 pr-1">{ Currency.format(product.price, product.shop.currency) }</span>
          <span class="h4 m-0 pr-2">{ Currency.format(price, product.shop.currency) }</span>
        </>
      )
    }

    return (
      <>
        <span class="h4 m-0 pr-2">Price:</span>
        <span class="h4 m-0">{ Currency.format(price, product.shop.currency) }</span>
      </>
    )
  }

  _renderRelatedProducts(product){

    if(product.related_products.length == 0){
      return null
    }

    return (
      <section class="product-blocks pt-4">
          <div class="container">
              <h2>Other Popular Products</h2>
              <div class="row">
                  {
                    product.related_products.map(relatedProduct => {
                      return (
                        <RelatedProduct
                          product={relatedProduct}
                          onAddToCartPressed={(product, variant) => this.props.onAddToCartPressed(relatedProduct, variant)}
                        />
                      )
                    })
                  }
              </div>
          </div>
      </section>
    )
  }

  _renderProductVariants(product){
    let {
      key,
      variant,
      options
    } = this.state

    if(product.variants.length === 0){
      return null
    }

    return options.map((productOption, index) => {
      return (
        <div className="input-select">
          <select
            value={variant[index]}
            onChange={e => {
              let selectedOptionName = e.target.value
              if(selectedOptionName === productOption.name){
                variant[index] = null
              }else{
                variant[index] = selectedOptionName
              }
              this.setState({ variant, key: key + 1 }, () => {
                setTimeout(() => { // reinitialize the carousel, and lazy background
                  General.updateSlides()
                  General.updateLazyBackgroundImages()
                }, 50)
              })
              let showAddToCart = Cart.showAddToCart(product, variant)
              if(showAddToCart){
                this._mobileAddToCartStickyBtn();
              }
            }}
          >
            <option>{productOption.name}</option>
            {
              productOption.values.map(option => (
                <option value={option.name}>{option.name}</option>
              ))
            }
          </select>
        </div>
      )
    })
  }

  _renderTabs(){
    let {
      product
    } = this.state

    if(product.infos.length === 0){
      return
    }

    return (
      <>
        {/* starts, Product Tabs */}
        <section class="pt-5 p-0">
            <div class="container">
              <div class="row justify-content-center">
                  <div class="col-md-12 p-0">
                    <div id="product-tabs" class="tabs-container tabs--folder">
                        <>
                          <ul class="tabs">
                            { this._renderTabTitles(product.infos)}
                          </ul>
                          <ul class="tabs-content">
                            { this._renderTabContent(product.infos)}
                          </ul>
                        </>
                    </div>
                    { General.renderTabStyle({
                        secondary_color: product.shop.website.primary_color,
                      }, "product-tabs") }
                  </div>
              </div>
            </div>
        </section>
        {/* } end, Product Tabs  */}
      </>
    )
  }

  _renderTabTitles(infos){
    return infos.map((info, index) => {
      let className = index === 0 ? "active" : ""
      let id = "textInfo_"+info.id
      return (
        <li className={className}>
          <div className="tab__title">
              <span>{ info.title }</span>
          </div>
        </li>
      )
    })
  }

  _renderTabContent(infos){
    return infos.map((info, index) => {
      let className = index === 0 ? "active" : ""
      let id = "textInfo_"+info.id
      return (
        <li key={ id } id={ id } className={className}>
          <div className="tab__content">
              <p className="pre">{ info.text }</p>
          </div>
        </li>
      )
    })
  }

  _renderAddToCart(){
    let {
      product,
      variant,
    } = this.state

    let outOfStock = Cart.outOfStock(product, variant)

    return (
      <>
        { !outOfStock &&
          <a
            class="btn btn--primary w-100 mb-2 m-fixed-btn-addtocart"
            onClick={() => this._handleAddToCart()}
          >
            <span class="btn__text">
              Add To Cart
            </span>
          </a>
        }
        { outOfStock &&
          <a
            class="btn btn--secondary w-100 mb-2"
            disabled={true}
            onClick={() => {}}
          >
            <span class="btn__text">
              Out Of Stock
            </span>
          </a>
        }
      </>
    )
  }

  _renderMeta(){
    let {
      product
    } = this.state

    if(product == null){
       return null
    }

    let website = product.shop.website
    let favIconUrl = website.favicon ? website.favicon.original : "/favicon.png"

    return (
      <Helmet>
          <title>{`${product.name} | ${website.name}`}</title>
          <meta name="description" content={ product.description || website.descrption } />
          <meta name="keywords" content={ product.tags.join(",") || website.keywords } />
          <link rel="icon" href={ favIconUrl }/>
          <meta property="og:title" content={ product.name }/>
          <meta property="og:image" content={ product.featured_image.thumbnail }/>
          <meta property="og:description" content={ product.description }/>
          <meta property="og:type" content="website"/>
          <meta property="og:url" content={ window.location.href }/>
          <meta property="twitter:card" content="summary_large_image"/>
          <meta property="twitter:url" content={ window.location.href }/>
          <meta property="twitter:title" content={ product.name }/>
          <meta property="twitter:description" content={ product.description }/>
          <meta property="twitter:image" content={ product.featured_image.thumbnail }/>
      </Helmet>
    )
  }


  _renderSlides(){
    let {
      key,
      product,
      variant
    } = this.state

    if(product == null){
       return null
    }

    let images = product.images
    if((product.options?.length > 0) && (variant.length === product.options.length)){
      let variantObject = Cart.getVariant(product, variant)
      if(variantObject && variantObject.images.length > 0){
        images = variantObject.images
      }
    }



    return (
      <ul key={key} className="slides">
        {
          images.map((image, index) => {
            return (
              <li
                className="lazy-background"
                key={index}
                style={{ backgroundImage: "url(" + getImageUrl(image, "product-page") + ")" }}
                priority={this.props.index}
              ></li>
            )
          })
        }
      </ul>
    )
  }


  render(){
    let {
      product,
      loading,
      variant,
      quantity,
      showShareModal
    } = this.state

    let showAddToCart = Cart.showAddToCart(product, variant)
    let stock = product.stock
    if((product.options?.length > 0) && (variant.length === product.options.length)){
      let variantObject = Cart.getVariant(product, variant)
      if(variantObject){
        stock = variantObject.stock
      }
    }

    return (
      <div class="main-container page product">
        { this._renderMeta() }
        {/* starts, main product section  */}
        <section class="switchable sticky-sidebars page-product pt-0 pb-4 pt-md-5">
            <div class="container">
              <div class="row justify-content-around">

                  {/* starts, column right, sticky sidebar  */}
                  <div class="col-md-4 sticky-sidebar-rightcol">
                    <div class="sidebar__inner">
                        <h2 className='fs-md-30'>{ product.name }</h2>
                        <div class="text-block d-flex align-items-center pb-3">
                          { this._renderPrice(product, variant) }
                          { product.on_sale &&
                            <span class="label position-static product-sale-price">Sale</span>
                          }
                        </div>
                        <div>
                          { this._renderProductVariants(product) }
                          <div class="row align-items-center mb-3 ml-0 mr-0">
                              <div class="col-12 p-0 m-0">
                                <div class="input-number">
                                  <input
                                    ref={this.quantityInput}
                                    type="number"
                                    name="quantity"
                                    placeholder="Quantity"
                                    value={ quantity }
                                    min="1"
                                    max={ stock }
                                    onChange={e => {
                                      this.setState({
                                        quantity: parseInt(e.target.value)
                                      })
                                    }}
                                  />
                                  <div class="input-number__controls">
                                    <span
                                      className="input-number__increase"
                                      onClick={() => {
                                        this.setState({
                                          quantity: quantity + 1
                                        })
                                      }}
                                    >
                                      <i className="stack-up-open"></i>
                                    </span>
                                    <span
                                      className="input-number__decrease"
                                      onClick={() => {
                                        let value = quantity - 1
                                        if (value <= 0) {
                                          value = 1
                                        }
                                        this.setState({
                                          quantity: value
                                        })
                                      }}
                                    >
                                      <i className="stack-down-open"></i>
                                    </span>
                                  </div>
                                </div>
                              </div>
                          </div>

                          {
                            showAddToCart && this._renderAddToCart()
                          }

                          {/* starts, Share this product  */}
                          <span className="link-share block type--fine-print text-center mb-2 d-flex justify-content-center align-items-center">
                            <span class="r-box material-icons bg-c-primary">share</span>
                            <a
                              href='javascript:void(0)'
                              className='text-uppercase type--underline link--primary'
                              onClick={() => {
                                this.setState({ showShareModal: true })
                              }}
                            >
                              Share this Product
                            </a>
                          </span>
                          <ShareModal
                            show={showShareModal}
                            title="Share This Product"
                            message="Copy the link below to share this product"
                            shareTitle="Checkout This Product"
                            url={window.location}
                            onHide={() => this.setState({showShareModal: false})}
                            onAdded={() => this.setState({showShareModal: false})}
                          />
                          {/* end, Share this product  */}

                        </div>
                    </div>
                  </div>
                  {/* end, column right, sticky sidebar  */}

                  {/* starts, column left  */}
                  <div class="col-md-8">

                    {/* starts, slider  */}
                    <div
                      className="slider-product-main slider border--round boxed--border"
                      data-paging="true"
                      data-arrows="true"
                    >
                        { this._renderSlides() }
                    </div>
                    <div
                      class="slider-product-nav slider border--round boxed--border"
                      data-asNavFor=".slider-product-main .slides"
                      data-asNavTrue="true"
                    >
                        { this._renderSlides() }
                    </div>
                    {/* end, slider  */}

                    {/* starts, Product Description  */}
                    <section class="switchable p-0">
                        <div class="container">
                          <div class="row justify-content-between">
                              <div class="col-md-12 col-lg-12 p-0">
                                <h2>Product Description</h2>
                                <div
                                  class="lead pre"
                                  dangerouslySetInnerHTML={{
                                    __html: product.description
                                  }}
                                />
                              </div>
                          </div>
                        </div>
                    </section>
                    {/* end, Product Description  */}

                    { this._renderTabs()}

                  </div>
                  {/* end, column left  */}

              </div>
            </div>
        </section>
        {/* end, main product section  */}

        { this._renderRelatedProducts(product) }

      </div>
    )
  }
}
