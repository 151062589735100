import React from 'react'

import moment from "moment";

import ReCAPTCHA from "react-google-recaptcha";

import Row from './Row'

import SuccessModal from "../modal/SuccessModal";
import PaymentDetails from '../PaymentDetails'

import General from '../../utils/General'
import Backend from "../../utils/Backend";
import Notify from "../../utils/Notify";
import Event from "../../utils/Event";
import Email from "../../utils/Email";
import Currency from "../../utils/Currency";

window.recaptchaOptions = {
  useRecaptchaNet: true,
};

export default class Form extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      data: props.data,
      form: props.form,
      answers: [],
    }

    this.recaptcha = React.createRef()
    this.paymentDetails = React.createRef()
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  componentDidMount(){
    let { form } = this.state

    if(this.props.preConfirguredBooking){
      form.form_fields.forEach((row, rowIndex) => {
        row.forEach((field, fieldIndex) => {
          if(field.type == "booking"){
            let answer = {
              field: field.id,
              booking: General.clone(this.props.preConfirguredBooking)
            }
            this._onAnswerUpdated(rowIndex, answer)
          }
        });
      })
    }
  }

  _setDefaultValues(formField){
    let {
      answers
    } = this.state

    if(formField.type === "boolean" && !formField.is_required){
      if(!answers.some(answer => answer.field === formField.id)){
        answers.push({field: formField.id, bool: false})
      }
    }else if(formField.type == "price" && formField.price){
      if(!answers.some(answer => answer.field === formField.id)){
        let answer = {field: formField.id, value: formField.price}
        formField.answer = answer
        answers.push(answer)
      }
    }

    this.setState({answers})
  }

  _handleSubmit(){
    let {
      form,
      answers
    } = this.state

    let error = null

    this.setState({loading: true})

    form.form_fields.map(formRow => {
      formRow.map(formField => {

        this._setDefaultValues(formField)

        if(formField.is_required){
          if(!answers.some(answer => answer.field === formField.id)){
            formField.error = true
            error = true
          }
        }

        if(formField.type === "email" || formField.type === "contact_email"){
          if(!formField.error){
            let answerIndex = answers.map(answer => {return answer.field}).indexOf(formField.id)
            if(!Email.isValid(answers[answerIndex].value)){
              formField.error = true
              error = true
            }
          }
        }

        if(formField.type === "phone" || formField.type === "contact_phone"){
          if(!formField.error){
            let answerIndex = answers.map(answer => {return answer.field}).indexOf(formField.id)
            if(answers[answerIndex].value.length < 6){
              formField.error = true
              error = true
            }
          }
        }

      })
    })

    this.setState({form})

    if(!error){
      let formData = {
        form: form.id,
        answers
      }
      this._submitForm(formData)
    }else{
      Notify.error("Please fill out all fields correctly.")
      this.setState({loading: false})
    }
  }

  _submitForm(formData){
    this.recaptcha.current.executeAsync()
    .then(token => {
      formData.recaptcha = token
      return this._addPaymentData(formData)
    })
    .then(formData => {
      return Backend.submitForm(formData)
    })
    .then((response) => {
      if(response.requires_action){
        this.paymentDetails.current.validatePayment(response, formData.sale.amount, window.CURRENT_WEBSITE_SHOP_CURRENCY)
        .then(() => this._handleSubmit())
        return
      }

      this._clearForm()
      this.recaptcha.current.reset()
      this.setState({showSubmissionSuccessModal: true, loading: false})
    }).catch(e => {
      this.recaptcha.current.reset()
      this.setState({loading: false})
      Notify.error(e.message)
    })
  }

  async _addPaymentData(formData){
    let { form, paymentIntentId } = this.state

    let monetaryField = this._getMonetaryField()

    if(!monetaryField){
      return formData
    }

    let firstNameField = this._getField("contact_first_name")
    let lastNameField = this._getField("contact_last_name")
    let emailField = this._getField("contact_email")

    let customer = {
      billing_address: {
        country_short: "IE"
      }
    }

    let amount = this._getMonetaryFieldAmount()

    return this.paymentDetails.current.getPaymentDetails(amount, customer)
    .then(paymentDetails => {
      let sale = {
        amount,
        field: monetaryField.id,
        email: emailField?.answer.value,
        first_name: firstNameField?.answer.value,
        last_name: lastNameField?.answer.value,
        payment_details: paymentDetails
      }
      formData.sale = sale
      return formData
    })
  }

  _clearForm(){
    let {
      form,
      answers
    } = this.state

    answers = []
    form.form_fields.map(formRow => {
      formRow.map(formField => {
        delete formField.answer
      })
    })

    this.setState({form, answers})

    Event.emit("CLOSE_PAYMENT_WIDGET");
  }

  _getField(type){
    let { form } = this.state
    for(var i = 0; i < form.form_fields.length; i++){
      const row = form.form_fields[i]
      for(var j = 0; j < row.length; j++){
        const field = row[j]
        if(field.type == type){
          return field
        }
      }
    }
    return null
  }

  _getMonetaryField(){
    let priceField = this._getField("price")
    if(priceField){
      return priceField
    }
    let bookingField = this._getField("booking")
    // bookings can be free, only return if there's a price
    if(bookingField?.answer?.booking?.price){
      return bookingField
    }
    return null
  }

  _getMonetaryFieldAmount(){
    let monetaryField = this._getMonetaryField()
    if(!monetaryField){
      return 0
    }
    return monetaryField.answer?.value || monetaryField.answer?.booking?.price
  }

  _getBillingDetails(){
    let firstNameField = this._getField("contact_first_name")
    let lastNameField = this._getField("contact_last_name")
    let emailField = this._getField("contact_email")
    let billingDetails = {}
    let name = ""
    if(firstNameField){
      name = firstNameField.answer.value + " "
    }
    if(lastNameField){
      name += lastNameField.answer.value + " "
    }

    if(name){
      billingDetails.name = name
    }
    if(emailField){
      billingDetails.email = emailField.answer.value
    }

    return billingDetails
  }


  _onAnswerUpdated(rowIndex, value){

    let {
      form,
      answers,
    } = this.state

    if(!answers.some(answer => answer.field === value.field)){
      answers.push(value)
    }else{
      let answerIndex = answers.map(answer => {return answer.field}).indexOf(value.field)
      answers[answerIndex] = value
    }

    form.form_fields[rowIndex].map(field => {
      if(field.id === value.field){
        field.answer = value
      }
    })

    this.setState({answers, form})
  }

  _renderTerms(termsText, tertiaryColor){
    if(!termsText){
      return null
    }

    return (
      <div className="col-md-12 text-center">
        <div className="modal-instance">
          <div className="input-checkbox">
            <input id="check-test" type="checkbox" name="agree" className="validate-required"/>
            <label htmlFor="check-test"></label>
          </div>
          <span>{ "I have read and agree to the "}
            <a
              href={termsText}
              target="_blank"
              className="modal-trigger"
            >
              terms and conditions
            </a>
          </span>
        </div>
      </div>
    )
  }

  _renderRows(rows){
    let {
      form,
      answers,
    } = this.state

    // keep track of the startt index for
    var fieldStartIndex = 0
    return rows.map((fields, rowIndex) => {
      if(rowIndex > 0){
        fieldStartIndex += rows[rowIndex - 1].length
      }

      let onlyBookingFields = fields.filter(
        field => field.type == "booking"
      ).length == fields.length

      if(onlyBookingFields && this.props.hideBookingField){
        return null
      }

      return (
        <>
          <Row
            key={ fields.map(field => field.id).join('_') }
            data={ fields }
            rowIndex={rowIndex}
            fieldStartIndex={ fieldStartIndex }
            onUpdated={(index, answer) => {
              this._onAnswerUpdated(rowIndex, answer)
            }}
            hideBookingField={this.props.hideBookingField}
          />
        </>
      )
    })
  }

  _renderSuccessModal(){
    let {
      form,
      showSubmissionSuccessModal
    } = this.state

    return (
      <SuccessModal
        show={showSubmissionSuccessModal}
        title={form.popup_title}
        message={form.popup_body}
        onHide={() => {
          this.setState({showSubmissionSuccessModal: false})
          if(window.location.pathname != "/"){
            window.location = "/"
          }
        }}
      />
    )
  }

  render(){
    let {
      data,
      form,
      loading,
      showSubmissionSuccessModal
    } = this.state

    if(!form){
      return null
    }

    let key = form.form_fields.map(formField => {
      return formField.map(field => field.id || field.uuid).join("_")
    }).join("_")

    let anchorStyle = {
      color: data.settings.senary_color,
    }

    let buttonDisabled = loading || (form.type === "payment" && !window.CURRENT_WEBSITE_SHOP) || window.CURRENT_WEBSITE.application_status !== "approved"

    let monetaryField = this._getMonetaryField()

    let amount = this._getMonetaryFieldAmount()
    let submitText = form.submit_text
    let submitTextLoading = "Saving..."
    if(amount){
      let currency = window.CURRENT_WEBSITE_SHOP_CURRENCY || ""
      submitText += ` (${Currency.format(amount, currency)})`
      submitTextLoading = "Processing..."
    }

    return (
      <>
        <form
          action={window.Api.Submissions}
          key={key}
          className="text-left form-email row mx-0"
          data-success="Thanks for your enquiry, we'll be in touch shortly."
          data-error="Please fill in all fields correctly."
          noValidate={true}
        >
          <div
            className="container"
          >
            { this._renderRows(form.form_fields) }

            { monetaryField &&
                <PaymentDetails
                  shop={window.CURRENT_WEBSITE_SHOP}
                  website={window.CURRENT_WEBSITE}
                  ref={this.paymentDetails}
                  onAsyncPaymentSuccess={() => {
                    this.setState({showSubmissionSuccessModal: true, loading: false})
                  }}
                  onAsyncPaymentFailed={() => {
                    Notify.error("Payment Failed, please try again.")
                    this.props.onAsyncPaymentFailed()
                  }}
                />
            }
          </div>

          <ReCAPTCHA
            ref={this.recaptcha}
            sitekey={window.Api.RecaptchaSiteKey}
            size="invisible"
          />

          <div className="col-md-12 form-btn">
            <div className="boxed px-0 bg-transparent">
              <button
                type="submit"
                className="btn btn--primary type--uppercase w-100"
                disabled={buttonDisabled}
                style={{
                  backgroundColor: data.settings.senary_color,
                  borderColor: data.settings.senary_color
                }}
                onClick={e => {
                  e.preventDefault()
                  this._handleSubmit()
                }}
              >
                <span
                  className="btn__text"
                  style={{
                    color: data.settings.quinary_color
                  }}
                >
                  { loading ? submitTextLoading : submitText }
                </span>
              </button>
            </div>
          </div>

          { this._renderTerms(form.terms_text, data.settings.tertiary_color) }
          <div className="col-md-12 text-center text-reCAPTCHA">
            This site is protected by reCAPTCHA and the Google
            <a href="https://policies.google.com/privacy" style={anchorStyle}> Privacy Policy</a> and
            <a href="https://policies.google.com/terms" style={anchorStyle}> Terms of Service</a> apply.
          </div>
        </form>
        {
          showSubmissionSuccessModal && this._renderSuccessModal()
        }
      </>
    )
  }

}

Form.defaultProps = {
  onAsyncPaymentFailed: () => {}
}
